.cart-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cart-container h2 {
  text-align: center;
  color: #333;
  font-family: 'Arial', sans-serif;
  margin-bottom: 20px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cart-table th,
.cart-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.cart-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.cart-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.checkout-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.checkout-button:hover {
  background-color: #45a049;
}

.datepicker-container {
  margin-bottom: 20px;
  text-align: left;
}

.datepicker-container label {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.datepicker-container input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media (max-width: 1409px) {
  .cart-container {
    margin: 20px 10px;
    padding: 20px;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 100px;
  }

  .cart-table th,
  .cart-table td {
    padding: 10px;
  }

  .checkout-button {
    float: none;
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
  }

  .datepicker-container label {
    display: block;
    margin-bottom: 10px;
  }

  .datepicker-container input {
    width: 100%;
  }
}
