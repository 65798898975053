.outside {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  min-height: 100vh; 
  box-sizing: border-box;
}

.error-message {
  color: red;
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 8px;
}

.loading {
  font-size: 18px;
  text-align: center;
  color: #333;
}


.loading {
  text-align: center;
  padding: 10px;
}

.data-table {
  width: 90%;
  border-collapse: collapse;
  font-size: 15px;
  text-align: right;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.data-table td input[type="number"],
.data-table td button {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  font-size: 1rem;
  text-align: right;
}

.data-table td input[type="number"] {
  max-width: none;
}

.data-table td button {
  margin-top: 0%;
}


.data-table th,
.data-table td {
  padding: 5px 10px;
  border: 1px solid #e2e2e2;
}

.data-table th {
  background-color: #164a72;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.data-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.data-table tbody tr:hover {
  background-color: #f1f1f1;
}

.data-table tbody tr {
  transition: background-color 0.3s ease;
}

.data-table td {
  color: #555555;
}

.error-message {
  color: red;
  font-weight: bold;
  margin: 20px 0;
  font-size: 18px;
}

.open-modal-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #164a72;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.open-modal-btn:hover {
  background-color: #0b3c57;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content .close:hover,
.modal-content .close:focus {
  color: #000000;
}

.add-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}

.add-item-icon {
  width: 50px;
  height: 50px;
  background-color: #164a72;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 0; /* This helps to center the + symbol */
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.add-item-icon:hover {
  background-color: #0b3c57;
}

.add-item-text {
  font-size: 18px;
  color: #164a72;
  font-weight: bold;
  text-align: center;
}

.dropdown-container {
  margin-bottom: 15px;
}

.dropdown-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
}

.modal-content p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.dropdown-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.dropdown-container select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  margin-right: 10px;
}

.quantity-input {
  width: 60px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
}

.delete-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #c0392b;
}

/* Mobile Styles */
@media (max-width: 1409px) {
  .data-table {
    font-size: 14px; 
    width: 100%;
    border: 0;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .data-table thead {
    display: none; 
  }

  .data-table,
  .data-table tbody,
  .data-table tr,
  .data-table td {
    display: block;
    width: 100%;
  }

  .data-table tr {
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
  }

  .data-table td {
    text-align: right;
    padding-left: 0%;
    position: relative;
    white-space: pre-wrap;
    word-wrap: break-word;
    right: 10px;
  }

  .data-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    top: 10px;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
  }

  .data-table td:last-child {
    border-bottom: 0;
  }
}
