.home-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.hero-section {
  background: linear-gradient(135deg, #113f5a, #7a9bb4);
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 15px;
}

.content-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  width: 100%;
}

.data-visualization {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.data-table {
  margin-left: 20px;
  width: 48%;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-table table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background-color: #0056b3;
  color: white;
}

.data-chart {
  margin-right: 50px;
  width: 48%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1409px) {
  .data-table {
    margin-left: 20px;
    margin-right: 40px;
    text-align: center;
  }

  .data-chart {
    display: none;
  }

  .hero-section {
    background: linear-gradient(135deg, #113f5a, #7a9bb4);
    color: white;
    text-align: center;
    margin-bottom: -150px;
    padding: 0px;
    margin-top: 145px;
  }
}

@media (max-width: 425px) {
  .hero-section {
    display: none;
  }
}
