.ascii-cat {
  position: absolute;
  bottom: 10px;
  left: 200px;
  font-size: 1px;
  animation: rainbow 3s infinite;
}

@keyframes rainbow {
  0%,
  100% {
    color: #b8336a;
  }
  5%,
  95% {
    color: #b93b73;
  }
  10%,
  90% {
    color: #c1427c;
  }
  15%,
  85% {
    color: #c94a85;
  }
  20%,
  80% {
    color: #d1528e;
  }
  25%,
  75% {
    color: #d95a97;
  }
  30%,
  70% {
    color: #e162a0;
  }
  35%,
  65% {
    color: #e96aa9;
  }
  40%,
  60% {
    color: #f172b2;
  }
  45%,
  55% {
    color: #f97abb;
  }
  50% {
    color: #ec5ebf;
  }
}

@media only screen and (max-width: 1099px) {
  .ascii-cat {
    display: none;
  }
}