/* General Sidebar Styles */
.sidebar {
  width: 200px;
  background: linear-gradient(135deg, #002a42, #7a9bb4);
  color: #fff;
  padding: 20px;
  height: 100vh; /* Fixed height for full-screen height */
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  z-index: 1000; /* Ensure sidebar is above other content */
}

.sidebar img {
  width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.sidebar h2 {
  text-align: center;
  margin: 20px 0;
}

.sidebar h2 a {
  color: white;
  text-decoration: none;
}

.sidebar h2 a:hover {
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 20px;
  cursor: pointer;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #c5c1c1;
  text-decoration: underline;
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.text-column {
  margin-left: 10px;
}

.sidebar .list-bottom {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
}

@media only screen and (max-width: 1409px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(135deg, #002a42, #7a9bb4);
    z-index: 1000;
  }

  .sidebar h2 {
    width: 100%;
    padding: 10px;
    background: linear-gradient(135deg, #002a42, #7a9bb4);
    text-align: center;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  .sidebar ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(135deg, #002a42, #7a9bb4);
    padding: 10px 0;
    z-index: 1001;
    margin: 0;
  }

  .sidebar li {
    margin-bottom: 0;
    flex-grow: 1;
    text-align: center;
    padding: 10px;
  }

  .sidebar li:first-child {
    margin-left: 5px;
  }

  .sidebar li:last-child {
    margin-right: 5px; /* Adjust for spacing on the right */
  }

  .icon-column {
    width: auto;
    margin-right: 0;
  }

  .sidebar .list-bottom {
    position: static;
    margin-top: 0;
  }

  .icon-column {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 599px) {
  .text-column {
    display: none;
  }
}

.outside {
  padding-top: 100px;
  padding-bottom: 60px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
